<template>
  <div @scroll="scrollScreen">
    <div class="pagdiv">
      <span class="pageTitle">案場排序</span>
    </div>

    <div class="borderColor p-4 mb-4">
      <button
        class="confirmButton my-2 mr-3"
        style="width: 100px; padding: 3px"
        @click="resetSortedData"
      >
        <i class="fas fa-undo"></i>&ensp;還原
      </button>

      <button
        class="confirmButton my-2"
        style="width: 120px; padding: 3px"
        @click="checkUpdateSortedData"
      >
        更新排序
      </button>

      <div class="my-3">請拖曳、排序案場名稱:</div>

      <div ref="sortableList" class="listWrap">
        <div
          v-for="(factoryName, idx) in sortingData"
          :key="idx"
          :id="`sortableListId${idx}`"
          class="listItem d-flex border rounded"
        >
          <span class="text-secondary listNum">{{ idx + 1 }}</span>
          {{ factoryName }}
        </div>
      </div>
    </div>

    <!-- backTop button -->
    <div class="backTop" @click="backTop" v-if="!clientTop">
      <i class="fas fa-chevron-up"></i>
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import { mapState, mapActions, mapMutations } from 'vuex'
import { updateSortedFactoriesApi } from '../api'

export default {
  name: 'sortsetting',
  data () {
    return {
      clientTop: true,
      initialData: [],
      sortingData: []
    }
  },
  computed: {
    ...mapState(['sortedFactoryNameList'])
  },
  methods: {
    ...mapMutations(['CHANGE_REALTIME_RELOAD']),
    ...mapActions(['asyncSortedFactories', 'asyncInfo']),
    scrollScreen (e) {
      this.clientTop = e.target.scrollTop === 0
    },
    backTop () {
      const element = document.getElementById('sortableListId0')
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start'
      })
    },
    resetSortedData () {
      this.sortingData = this.initialData
    },
    async checkUpdateSortedData () {
      this.$swal
        .fire({
          icon: 'warning',
          title: `確定更新案場排序?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `確定`,
          denyButtonText: `取消`,
          reverseButtons: true
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.updateSortedFactories(this.sortingData)
          }
        })
    },
    async updateSortedFactories (updateArray) {
      try {
        const result = await updateSortedFactoriesApi(updateArray)
        if (result?.data?.success) {
          this.$swal('更新成功')

          await this.asyncSortedFactories()
          await this.asyncInfo()
          this.CHANGE_REALTIME_RELOAD(true)
        }
      } catch (error) {
        this.$swal('更新發生問題，請重試或洽管理員')
        console.log('updateSortedFactoriesApi', error)
      }
    }
  },
  async mounted () {
    if (!this.sortedFactoryNameList?.length) await this.asyncSortedFactories()

    // factoryName list
    this.initialData = this.sortedFactoryNameList
    this.sortingData = this.sortedFactoryNameList

    // Sortablejs
    this.$nextTick(() => {
      Sortable.create(this.$refs.sortableList, {
        sort: true,
        animation: 500,
        // 此寫法 fix 資料更新但畫面沒更新
        onEnd: (e) => {
          let clonedItems = this.sortingData.filter((item) => item)
          clonedItems.splice(
            e.newIndex,
            0,
            clonedItems.splice(e.oldIndex, 1)[0]
          )
          this.sortingData = []
          this.$nextTick(() => {
            this.sortingData = clonedItems
          })
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/global.scss";

.listWrap {
  column-width: 300px;
  column-gap: 25px;
}

.listItem {
  cursor: grab;
  font-size: 15px;
  padding: 10px 15px;
  margin-bottom: 5px;

  &:active {
    background-color: #eee;
  }
}
.listNum {
  min-width: 40px;
}
</style>
